import React from 'react';

const Container = ({ children, center }) => (
    <div
        style={{
            margin: `0 auto`,
            maxWidth: 1200,
            padding: `40px 1.0875rem 1.45rem`,
            fontFamily: 'Roboto',
            textAlign: 'center',
        }}
    >
        <main>{children}</main>
    </div>
);

export default Container
import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { Grid } from '@material-ui/core';
import NavbarLinks from './NavbarLinks';
import DownloadButton from './DownloadButton';

const useStyles = makeStyles(() => ({
    navbar: {
        background: 'none',
        zIndex: 100,
        paddingLeft: 50,
        paddingRight: 50,
    },
    logo: {
        width: 140,
        marginRight: 40
    },
    toolbar: {
        minHeight: 90
    }
}))

function HideOnScroll(props) {
    const { children } = props;

    const trigger = useScrollTrigger({ threshold: 100, disableHysteresis: true });

    const theme = useTheme();

    return React.cloneElement(children, {
        style: {
            boxShadow: trigger ? theme.shadows[3] : 'none',
            background: trigger ? 'rgba(21, 26, 49, .95)' : 'none',
            transition: theme.transitions.create(['background', 'boxShadow'], {
                duration: theme.transitions.duration.complex
            })
        }
    });
}

const Header = ({ props, links }) => {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query {
                file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    # Specify a fixed image and fragment.
                    # The default width is 400 pixels
                    fixed(width: 140) {
                    ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `)

    return (
        <HideOnScroll {...props}>
            <AppBar className={classes.navbar}>
                <Toolbar className={classes.toolbar}>
                    <Grid
                        justify="space-between"
                        alignItems="center"
                        container
                        direction="row"
                        spacing={10}
                    >
                        <Grid item>
                            <Img fadeIn={false} fixed={data.file.childImageSharp.fixed} alt="8Sense" className={classes.logo} />
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                wrap="nowrap"
                            >
                                <Grid item>
                                    <NavbarLinks links={links} />
                                </Grid>
                                <Grid item>
                                    <DownloadButton />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

        </HideOnScroll>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header

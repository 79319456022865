import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactCardFlip from 'react-card-flip';

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
    },
    cardContent: {
        height: 220,
        paddingTop: 15
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 32,
        fontWeight: 400,
        textAlign: "center",
        marginBottom: 12,
        textTransform: 'uppercase',
        letterSpacing: 5,
    },
    titleBorder: {
        borderBottomColor: 'red',
        borderBottomWidth: 20,
    },
    body: {
        fontSize: 14,
        fontWeight: 100,
        textAlign: "center"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    cardActions: {
        textAlign: "center",
        justifyContent: "center"
    },
    cardBack: {
        position: "absolute",
        height: 200
    },
    scrollableDiv: {
        height: 188,
        overflow: "auto"
    }
}));

export default function FlipCard({ title, mainContent, fullContent }) {
    const classes = useStyles();
    const [flipped, setFlipped] = useState(false);

    const handleFlipClick = () => {
        setFlipped(!flipped);
    };

    return (
        <ReactCardFlip isFlipped={flipped} flipDirection="vertical">
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <Typography variant="h5" component="h1" className={classes.title}>
                        <span style={{
                            marginRight: -5,
                            borderBottomColor: '#c41110',
                            borderBottomWidth: .5,
                            borderBottomStyle: 'solid'
                        }}>{title}</span>
                    </Typography>
                    <Typography variant="body2" component="p" className={classes.body}>
                        {mainContent}
                    </Typography>
                </CardContent>
                {fullContent !== "" ? <CardActions className={classes.cardAction}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: flipped,
                        })}
                        onClick={handleFlipClick}
                        aria-expanded={flipped}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions> : <CardContent style={{ height: 67.5 }}></CardContent>}
            </Card>

            <Card className={classes.card}>
                <CardContent className={`${classes.cardBack}, ${classes.cardContent}`}>
                    <div className={classes.scrollableDiv}>
                        <Typography variant="body2" component="p" className={classes.body}>
                            {fullContent}
                        </Typography>
                    </div>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: flipped,
                        })}
                        onClick={handleFlipClick}
                        aria-expanded={flipped}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
            </Card>
        </ReactCardFlip>
    );
}
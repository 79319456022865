import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let SenseTheme = createMuiTheme({
    typography: {
        h2: {
            fontWeight: 300,
            fontSize: 3.25
        },
        body1: {
            color: '#fff'
        }
    },
    palette: {
        primary: {
            main: '#fff',

        }
    }
});

SenseTheme = responsiveFontSizes(SenseTheme);

export default SenseTheme;
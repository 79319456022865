import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "react-scroll";

const useStyles = makeStyles(() => ({
    button: {
        fontWeight: 500,
        textTransform: 'uppercase',
        letterSpacing: 1.2,
        width: 200,
        "&:hover": {
            backgroundColor: "rgba(52, 102, 103, 1.00)",
            borderColor: "rgba(52, 102, 103, 1.00)"
        }
    },
}))

const DownloadButton = () => {
    const classes = useStyles();

    return (
        <>
            <Link
                to={'download-app'}
                spy={true}
                smooth={true}
                offset={-100}
                duration={600}
            >
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    className={classes.button}

                >Download the App</Button>
            </Link>
        </>
    )
}

export default DownloadButton;
import React from "react"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid";

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import CardSection from "../components/CardSection"
import Container from "../components/Container"
import HeroSection from "../components/HeroSection"
import PhoneImage from "../components/PhoneImage"
import AppleStoreBadge from "../components/AppleStoreBadge"
import PlayStoreBadge from "../components/PlayStoreBadge"

import backgroundImage from '../images/backgroundImage.png'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection image={backgroundImage}>
      There are thousands of ways to meditate.<br />
      The only one that matters is yours.
    </HeroSection>
    <Container center={true}  >
      <Typography variant="h2" id="experience-8-sense" style={{ marginBottom: 45 }} >
        Experience 8Sense
      </Typography>
      <CardSection />
    </Container>

    <Container center>
      <Grid container alignItems="center" spacing={10}>
        <Grid item xs={12} md={6} style={{ flex: 1 }}>
          <PhoneImage />
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
          <Typography variant="h4" gutterBottom id="download-app">
            Download the App
          </Typography>
          <Typography variant="body2">
            The links below will take you to download the app either on iOS or Android.
          </Typography>

          <Grid container style={{ marginTop: 15 }}>
            <Grid item>
              <AppleStoreBadge />
            </Grid>
            <Grid item>
              <PlayStoreBadge />
            </Grid>
          </Grid>

        </Grid>
      </Grid>

    </Container>
  </Layout >
)

export default IndexPage

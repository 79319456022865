import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const AppleStoreBadge = () => {

    const playStore = useStaticQuery(graphql`
        query {
                file(relativePath: { eq: "google-play-badge.png" }) {
                childImageSharp {
                    # Specify a fixed image and fragment.
                    # The default width is 400 pixels
                    fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <div style={{ width: 175 }}>
            <a href="http://google.com">
                <Img draggable={false} fadeIn={false} fixed={playStore.file.childImageSharp.fluid} alt="Play Store Badge" style={{ width: 188, height: 50 }} />
            </a>
        </div >
    )




};

export default AppleStoreBadge;

import React from 'react'

const HeroSection = ({ children, image }) => {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 500,
                marginBottom: 20,
                backgroundImage: `url(${image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                fontFamily: 'Roboto',
                color: 'white',
                textAlign: 'center',
                fontSize: 36,
                lineHeight: 1.3,
                fontWeight: 500
            }}
        >
            {children}
        </div >
    )
}



export default HeroSection
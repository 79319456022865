import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image';

const PhoneImage = () => {
    const data = useStaticQuery(graphql`
    query {
            file(relativePath: { eq: "phoneMockup.png" }) {
            childImageSharp {
                # Specify a fixed image and fragment.
                # The default width is 400 pixels
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`)

    console.log(data.file.childImageSharp.fluid);
    return (
        <div style={{ maxWidth: 600 }}>
            <Img draggable={false} fadeIn={false} fluid={data.file.childImageSharp.fluid} alt="8Sense Mockup" />
        </div>
    )
}

export default PhoneImage
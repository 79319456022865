import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Grid from "@material-ui/core/Grid"
import FlipCard from "./FlipCard"

const CardSection = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allMarkdownRemark(sort: { fields: frontmatter___order }, filter: { frontmatter: { location: { eq: "HomeScreen" } } }) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            front
                            back
                        }
                    }
                }
            }
          }
        `
    )

    const cardsData = data.allMarkdownRemark.edges;

    return (
        <>
            <Grid container spacing={3}>
                {cardsData.map(({ node }) => (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        key={node.id}
                    >
                        <FlipCard
                            title={node.frontmatter.title}
                            mainContent={node.frontmatter.front}
                            fullContent={node.frontmatter.back}
                        />
                    </Grid>
                )
                )}
            </Grid>
        </>
    )
}

export default CardSection
import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from "react-scroll";

const useStyles = makeStyles(theme => ({
    list: {
        fontWeight: 400,
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.complex
        }),
        "&:hover": {
            color: 'rgba(52, 102, 103, 1.00)',
            cursor: 'default'
        }
    }
}))

const NavbarLinks = ({ links }) => {
    const classes = useStyles();

    const data = useStaticQuery(graphql`
        query LinksQuery {
            allLinksJson {
            edges {
                node {
                label
                link
                }
            }
            }
        }
    `)

    const navData = data.allLinksJson.edges;

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
        >
            {navData.map(({ node }) => (
                <Grid item style={{ flex: 1 }}>
                    <Link
                        to={node.link}
                        spy={true}
                        smooth={true}
                        offset={-100}
                        duration={600}
                    >
                        <Typography
                            noWrap={true}
                            variant="body1"
                            className={classes.list}
                        >
                            {node.label}
                        </Typography>
                    </Link>
                </Grid >
            )
            )}
        </Grid >
    )
}

export default NavbarLinks